import axios from 'axios'

const apiClient = axios.create({
  baseURL: 'https://web-api-sgp.piebarometre.digital/',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  },
})

export default {
  getAGR () {
    return apiClient.get('api/request-parametre-agr/get-infocollecte-agr')
  },
  getInfoCollects (id) {
    return apiClient.post('api/request-parametre-agr/get-agr-detail-infocollecte', {
      id_info_collectes: id,
    })
  },
}
